import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {CategoryModel, SingleCategoryModel} from "@/App/categories/types";

interface InitialState {
    categories: CategoryModel[] | [],
    singleCategory: SingleCategoryModel | null,
}

const initialState: InitialState = {
    categories: [],
    singleCategory: null,
};

export const categorySlice = createSlice({
    initialState,
    name: 'categoriesSlice',
    reducers: {
        setCategories: (state, action: PayloadAction<CategoryModel[]>) => {
            state.categories = action.payload;
        },
        setSingleCategory: (state, action: PayloadAction<SingleCategoryModel>) => {
            state.singleCategory = action.payload;
        },

    },
});
