import React from 'react';
import { Link } from 'react-router-dom';
import {Facebook, Instagram, LinkedIn, YouTube} from "@mui/icons-material";
import X from '@mui/icons-material/X';

const Footer = () => {
    return (
        <div className="flex flex-col gap-3 w-full pb-10 mt-10">
            <div className="w-full flex sm:justify-between items-end px-10 flex-wrap justify-center">
                <h4 className="text-gray-400 text-sm ">
                    @2024 RG Tickets, All rights reserved.
                </h4>

                <div className="flex flex-col items-start justify-center gap-4">
                    <div className="flex flex-row justify-start w-full">
                        <div className="flex flex-row justify-start gap-3 text-sm">
                            <Link to='/' className='text-gray-600'>Home</Link>
                            <Link to='/events' className='text-gray-600'>Events</Link>
                            <Link to='/contact-us' className='text-gray-600'>Contact Us</Link>
                        </div>
                    </div>
                    <div className="ul flex flex-row gap-2">
                        <a href={"https://www.facebook.com/rgtickets"}
                           className="p-[5px] text-xs cursor-pointer w-6 h-6 flex justify-center items-center">
                            <Facebook/>
                        </a>
                        <a href={"https://www.instagram.com/rgticketsrw/"}
                           className="p-[5px] text-xs cursor-pointer w-6 h-6 flex justify-center items-center">
                            <Instagram/>
                        </a>
                        <a href={"https://twitter.com/RgTickets"}
                           className="p-[5px] text-xs cursor-pointer w-6 h-6 flex justify-center items-center">
                            <X/>
                        </a>
                        <a href={"https://www.youtube.com/@rgconsultincofficial"}
                           className="p-[5px] text-xs cursor-pointer w-6 h-6 flex justify-center items-center">
                            <YouTube/>
                        </a>
                        <a href={"https://www.linkedin.com/in/rg-consult-835a74201/?originalSubdomain=rw"}
                           className="p-[5px] text-xs cursor-pointer w-6 h-6 flex justify-center items-center">
                            <LinkedIn/>
                        </a>
                    </div>
                    <div className="flex flex-col">
                        <a href={"/privacy-policy"} className={"hover:underline text-sm text-black"}>Our Privacy
                            Policy.</a>
                        <a href={"/terms-and-conditions"} className={"hover:underline text-sm text-black"}>Terms and
                            Conditions.</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
