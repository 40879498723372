import Footer from '@/components/Footer';
import Header from '@/components/Header';
import React, { Fragment, useState } from 'react';
import { storage } from '@/utils';
import formatDateToLongForm from '@/utils/DateFormattter';
import toast from 'react-hot-toast';
import { CircularProgress } from '@mui/material';
import { Dialog, Transition } from '@headlessui/react';
import {
    BuildingOffice2Icon,
    CalendarDaysIcon,
    CurrencyDollarIcon,
    MapIcon,
    MapPinIcon,
    PhoneIcon,
    QrCodeIcon,
    UserCircleIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline';
import { FaDotCircle } from 'react-icons/fa';
import IconNotes from '@/components/Icon/IconNotes';
import IconCalendar from '@/components/Icon/IconCalendar';
import { useAppSelector } from '@/store';
import checkUser from '@/utils/checkUser';
import {useGetMyTicketsQuery} from "@/App/Tickets/api";
import {EventModel, Ticket} from "@/App/Events/types";
import useCheckUser from "@/utils/checkUser";

type TicketDetails = {
    ticket: Ticket | null;
    event: EventModel | null;
    status: any;
};

export const MyTickets = () => {

    const check = useCheckUser();

    const { isLoading, data } = useGetMyTicketsQuery(
        { pageNumber: 1, pageSize: 1000000 },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const [modal1, setModal1] = useState(false);

    const [selected, setSelected] = useState<TicketDetails>({
        ticket: null,
        event: null,
        status: '',
    });

    function openModal(ticket: any, event: any, status: any) {
        setSelected({ ticket: ticket, event: event, status: status });
        setModal1(true);
    }

    const logout = () => {
        storage.removeToken();
        location.href = '/';
        toast.error('You logged out');
    };

    const { user } = useAppSelector((state: any) => state.userState);


    const [tab, setTab] = useState(0);

    return (
        <main className="Container bg-gray-100 flex justify-center flex-col items-center ">
            <Header />

            <div className="w-full flex justify-center items-center">
                <div className="w-full gap-4 justify-start items-center md:items-start md:w-11/12 min-h-[600px] flex flex-col md:flex-row md:max-w-[1000px] py-10">
                    <div className="w-11/12 max-h-96  bg-white p-5 md:w-1/3">
                        <h3 className="text-xl text-center border-b pb-3 font-normal text-gray-600 dark:text-white">
                            Profile
                        </h3>

                        <div className="flex mt-5 flex-col">
                            <h3 onClick={() => setTab(0)} className="text-xl text-center border-t hover:bg-Maincolor hover:text-white cursor-pointer py-3 font-normal text-gray-600 dark:text-white">
                                My Tickets
                            </h3>
                            <h3 onClick={() => setTab(1)} className="text-xl text-center border-t hover:bg-Maincolor hover:text-white cursor-pointer py-3 font-normal text-gray-600 dark:text-white">
                                Profile
                            </h3>
                            <h3
                                onClick={() => logout()}
                                className="text-xl text-center border-t hover:bg-Maincolor hover:text-white cursor-pointer py-3 font-normal text-gray-600 dark:text-white"
                            >
                                Log Out
                            </h3>
                        </div>
                    </div>

                    {tab === 0 ? (
                        <div className="w-11/12 min-h-[600px] bg-white p-5 md:w-2/3">
                            <h3 className="text-xl text-center border-b pb-3 font-normal text-gray-600 dark:text-white">
                                Your Tickets
                            </h3>
                            {isLoading ? (
                                <div className="w-full h-56 flex justify-center items-center">
                                    <div className=" flex flex-row justify-center items-center">
                                        <CircularProgress
                                            className="text-Maincolor"
                                            size={24}
                                            sx={{ color: '#8cc541' }}
                                        />
                                    </div>
                                </div>
                            ) : !data || data.list.length === 0 ? (
                                <div className="w-full h-56 flex justify-center items-center">
                                    No Tickets Purchased.
                                </div>
                            ) : (
                                <div className="grid max-h-[32rem] overflow-x-scroll scrollbar-hidden grid-col-2 sm:grid-cols-3 gap-2 p-5">
                                    {data?.list.map((event) => (
                                        <div
                                            onClick={() =>
                                                openModal(
                                                    event.pricing,
                                                    event?.event,
                                                    event
                                                )
                                            }
                                            key={event.id}
                                            className="w-full"
                                        >
                                            <div className="max-w-sm cursor-pointer hover:-translate-x-[2px] transition-all hover:-translate-y-[4px] border-Maincolor/30 border flex flex-col rounded-lg shadow-lg dark:bg-gray-800 ">
                                                <div className="text-sm p-2 text-start">
                                                    <div className="flex flex-col gap-2 justify-between">
                                                        <h5 className="text-lg font-medium  text-Maincolor">
                                                            {
                                                                event?.event
                                                                    ?.title
                                                            }
                                                        </h5>
                                                        <p className='text-gray-500'>{event?.pricing.type}</p>
                                                        <p className='text-gray-500'>{formatDateToLongForm(event?.created_at)}</p>
                                                    </div>
                                                </div>

                                                <div className="flex flex-row justify-end p-2">
                                                    <div className="w-6 cursor-pointer text-Maincolor p-1 rounded-xl  bg-white">
                                                        <QrCodeIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ) : tab === 1 ? (
                        <div className="w-11/12 min-h-[600px] bg-white p-5 md:w-2/3">
                            <h3 className="text-xl text-center border-b pb-3 font-normal text-gray-600 dark:text-white">
                                Your Profile
                            </h3>
                            {user == null ? (
                                <div className="w-full h-56 flex justify-center items-center">
                                    <div className=" flex flex-row justify-center items-center">
                                        <CircularProgress
                                            className="text-Maincolor"
                                            size={24}
                                            sx={{ color: '#8cc541' }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-col px-5 justify-center items-center">
                                    <div className="w-[6rem] h-[6rem] text-white my-5 rounded-full bg-Maincolor">
                                        <UserCircleIcon />
                                    </div>
                                    <div className="p-5 py-0 text-center">
                                        <div className="text-[30px]">{user.username}</div>
                                        <div className="text-md sm:text-[20px] text-Maincolor">{user.email}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : ''}
                </div>
            </div>

            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center    min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full bg-white  max-w-3xl text-black dark:text-white-dark "
                                >
                                    <div className="flex bg-white item-center items-center justify-between px-5 py-3">
                                        <div className="text-lg font-bold flex flex-row justify-center w-full ">
                                            {' '}
                                            {selected.event?.title}
                                        </div>

                                        <div
                                            onClick={() => setModal1(false)}
                                            className="w-10 cursor-pointer p-2"
                                        >
                                            <XMarkIcon />
                                        </div>
                                    </div>
                                    <div className="p-5 bg-gray-100 gap-3 flex flex-col md:flex-row justify-center items-start">
                                        <div className="w-full flex flex-col gap-3 md:w-[40%]">
                                            <div className="flex items-start w-full gap-2 flex-col">
                                                {selected.status.id && (
                                                    <img
                                                        src={`https://quickchart.io/qr?text=${encodeURIComponent(
                                                            selected.status.ticket_id
                                                        )}`}
                                                        className="w-full"
                                                     alt={"image"}></img>
                                                )}
                                                <div className="py-2 w-full items-center flex flex-row justify-between">
                                                    {selected.status
                                                        .scanned_at == null ? (
                                                        <div className="flex w-full flex-row bg-white p-2 items-center rounded-md">
                                                            <div className="w-3 h-3 flex justify-center items-center text-Maincolor bg-gray-100 shadow-sm rounded-xl">
                                                                <FaDotCircle className="w-2" />
                                                            </div>
                                                            <div className="px-3">
                                                                <p className="text-gray-500 text-xs">
                                                                    Ticket Not
                                                                    Scanned
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="flex w-full flex-row bg-white p-2 items-center rounded-md">
                                                            <div className="flex flex-row">
                                                                <div className="w-3 h-3 flex justify-center items-center text-red-600 bg-gray-100 shadow-sm rounded-xl">
                                                                    <FaDotCircle className="w-2" />
                                                                </div>
                                                                <div className="px-3">
                                                                    <p className="text-gray-500 text-xs">
                                                                        Ticket
                                                                        Scanned
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <p className="text-gray-500 text-xs">
                                                                {formatDateToLongForm(
                                                                    selected
                                                                        .status
                                                                        .scanned_at
                                                                )}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="w-full bg-white p-5">
                                                <h3 className="text-xl text-center border-b pb-3 font-normal text-gray-600 dark:text-white">
                                                    Ticket Details
                                                </h3>
                                                <div className="py-4 px-2 items-center flex flex-row">
                                                    <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                                        <IconNotes />
                                                    </div>
                                                    <div className="px-3">
                                                        <p className="text-gray-500 text-sm">
                                                            Ticket Type
                                                        </p>
                                                        <p>
                                                            {
                                                                selected.ticket?.type
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="py-4 px-2 items-center flex flex-row">
                                                    <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                                        <CurrencyDollarIcon />
                                                    </div>
                                                    <div className="px-3">
                                                        <p className="text-gray-500 text-sm">
                                                            Price
                                                        </p>
                                                        <p>
                                                            {selected.ticket?.price && <>{selected.ticket?.price.toLocaleString()} {selected.event?.currency}</> }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="py-4 px-2 items-center flex flex-row">
                                                    <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                                        <IconCalendar />
                                                    </div>
                                                    <div className="px-3">
                                                        <p className="text-gray-500 text-sm">
                                                            Date Purchased
                                                        </p>
                                                        <p>
                                                            {formatDateToLongForm(
                                                                selected.status
                                                                    .created_at
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full md:w-[60%] bg-white p-5">
                                            <h3 className="text-xl text-center border-b pb-3 font-normal text-gray-600 dark:text-white">
                                                Event Details
                                            </h3>
                                            <div className="py-4 px-2 items-center flex flex-row">
                                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                                    <CalendarDaysIcon />
                                                </div>
                                                <div className="px-3">
                                                    <p className="text-gray-500 text-sm">
                                                        Start Date
                                                    </p>
                                                    <p>
                                                        {formatDateToLongForm(
                                                            selected.event?.date
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="py-4 px-2 items-center flex flex-row">
                                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                                    <MapIcon />
                                                </div>
                                                <div className="px-3">
                                                    <p className="text-gray-500 text-sm">
                                                        Country
                                                    </p>
                                                    <p>
                                                        {selected.event?.country}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="py-4 px-2 items-center flex flex-row">
                                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                                    <MapPinIcon />
                                                </div>
                                                <div className="px-3">
                                                    <p className="text-gray-500 text-sm">
                                                        Location
                                                    </p>
                                                    <p>
                                                        {selected.event?.location}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="py-4 px-2 items-center flex flex-row">
                                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                                    <BuildingOffice2Icon />
                                                </div>
                                                <div className="px-3">
                                                    <p className="text-gray-500 text-sm">
                                                        Event Organizer
                                                    </p>
                                                    <p>
                                                        {selected.event?.organiser}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="py-4 px-2 items-center flex flex-row">
                                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                                    <PhoneIcon />
                                                </div>
                                                <div className="px-3">
                                                    <p className="text-gray-500 text-sm">
                                                        Contacts
                                                    </p>
                                                    <p>
                                                        {
                                                            selected.event?.phoneNumber
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            {/* Footer */}
            <Footer />
        </main>
    );
};

function TicketModal({ event }: { event: any }) {
    return <div className=""></div>;
}
