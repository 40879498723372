import Footer from "@/components/Footer"
import Header from "@/components/Header"
import { Link } from "react-router-dom"
import tick from "@/Images/Checkmark.png";

function AfterPayment() {
  return (
    <div className="bg-gray-100 flex flex-col items-center justify-between">
        <Header />
            <div className="max-w-[900px] w-full flex flex-col items-center">
                <div className="w-full items-center mt-10 text-center flex flex-col gap-4 p-5 shadow-md bg-white">

                    <div className="text-4xl"><img src={tick} className={"w-32"} alt="success image"/></div>

                    <h1 className="text-4xl font-bold text-Maincolor">Thank You!</h1>

                    <h1 className="text-3xl">Payment Initiated</h1>

                    <p className="text-xl">
                        Dial <div className="font-semibold">*182*7*1# </div> to complete payment.
                    </p>

                    <p className="text-gray-500">
                        *An email or text message will be sent to you with the ticket link after successful payment.
                    </p>

                    <Link to="/account" className="p-5 cursor-pointer max-w-[400px] border border-Maincolor">
                        Go To Tickets
                    </Link>
                </div>
            </div>
        <Footer/>
    </div>
  )
}

export default AfterPayment
