import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {MySingleTicketModel, MyTicketsModel, MyTicketsResponseModel} from "@/App/Tickets/types";

interface InitialState {
    my_tickets: MyTicketsResponseModel | null,
    single_ticket: MySingleTicketModel | null,
}

const initialState: InitialState = {
    my_tickets: null,
    single_ticket: null,
};

export const myTicketsSlice = createSlice({
    initialState,
    name: 'myTicketsSlice',
    reducers: {
        setMyTickets: (state, action: PayloadAction<MyTicketsResponseModel>) => {
            state.my_tickets = action.payload;
        },

        setSingleTickets: (state, action: PayloadAction<MySingleTicketModel>) => {
            state.single_ticket = action.payload;
        },

    },
});
