
import baseApi from "@/core/lib/base";
import { userSlice } from "./user";
import { UserModel } from "./user";
import toast from "react-hot-toast";
import { ResponseModel } from "@/core/types";
import { storage } from "@/utils";
import { LoginModel } from "./types";
import { LoginInput } from "../login";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ResponseModel<LoginModel>, LoginInput>({
      query: (payload) => ({
        method: "POST",
        url: "/api/v1/user/login",
        body: payload,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;

          toast.success("Login successful");
          dispatch(userSlice.actions.setUser(data));
          storage.setToken(data.access_token);
        } catch (error: any) {
          console.log(error)
          toast.error(error?.error?.data?.message);
        }
      },
    }),

    whoami: builder.query<ResponseModel<UserModel>, null>({
      query: () => ({
        method: "GET",
        url: "/api/v1/user/check",
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
          dispatch(userSlice.actions.setUser(data));
        } catch (error) {}
      },
    }),

  }),
});

export const {
  useLoginMutation,
  useWhoamiQuery,
} = authApi;
