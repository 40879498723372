import Footer from '@/components/Footer';
import Header from '@/components/Header';
import React from 'react';
import {EventCard, EventLoader} from "@/components/appComponents";
import {useParams} from "react-router-dom";
import {useGetSingleCategoryQuery} from "@/App/categories/api";

export const CategoryEvents = () => {
    const { id } = useParams();

    const { isFetching, data } = useGetSingleCategoryQuery({ id: id ?? '' },
        {

            refetchOnMountOrArgChange: true,
        }
    );

    return (
        <main className="">
            <Header />

            <div className="w-full min-h-[600px] bg-gray-100 py-10">
                <div className="w-10/12 max-w-[1200px] min-h-full mx-auto">
                    {isFetching ? <div className="h-8 bg-gray-50 my-5 max-w-[300px] w-full animate-pulse"></div> : <h1 className="w-full header-font text-[15px] capitalize lg:text-[25px] font-bold">
                        {data?.name} events
                    </h1> }
                    {data?.events.length == 0 ? <div className="p-10 flex justify-center items-center h-32">No events Available</div> : <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2   lg:grid-cols-3 xl:grid-cols-3 w-full gap-16 justify-between min-h-[300px] mt-5">
                        {isFetching ? [1,1,1].map((_, i) => <EventLoader key={i} />) : data?.events.map((event) => (
                            <EventCard key={event.id} event={event}/>
                        ))}
                    </div> }
                </div>
            </div>

            <Footer />
        </main>
    );
};
