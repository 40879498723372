import Header from "@/components/Header";
import {FaSearch} from "react-icons/fa";
import {EventCard, EventLoader} from "@/components/appComponents";
import Footer from "@/components/Footer";
import React from "react";
import { useGetPastEventsQuery } from "@/App/Events/api";

export default function PastEvents() {

    const { isLoading, data } = useGetPastEventsQuery(
        { pageNumber: 1, pageSize: 10 },
        {

            refetchOnMountOrArgChange: true,
        }
    );

    return (
        <main className="">
            <Header />

            <div className="w-full min-h-[600px] bg-gray-100 py-10">
                <div className="w-10/12 max-w-[1200px] min-h-full mx-auto">
                    <h1 className=" header-font w-full text-[15px] lg:text-[25px] font-bold">
                        Past events
                    </h1>
                    {data?.list.length == 0 ? <div className="p-10 flex justify-center items-center h-32">No events Available</div> : <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2   lg:grid-cols-3 xl:grid-cols-3 w-full gap-16 justify-between min-h-[300px] mt-5">
                        {isLoading ? [1,1,1].map((_, i) => <EventLoader key={i} />) : data?.list.map((event) => (
                            <EventCard key={event.id} event={event}/>
                        ))}
                    </div> }
                </div>
            </div>

            <Footer />
        </main>
    );
}
