import React, { useEffect } from 'react';
import { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Form } from '@/components/form';
import { z } from 'zod';
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import mtn from '../../../Images/mtn.png';
import visa from '@/Images/visa.png';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';
import banner from '../../../Images/banner.png';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { EmailBox } from '@/components/appComponents';
import toast from 'react-hot-toast';
import {retrieveObjectFromLocalStorage} from "@/utils/storage";
import {calculateTotal} from "@/App/Events/single_event";
import airtel from '@/Images/airtel.png';
import masterCard from '@/Images/master.png';
import wu from '@/Images/wu.png';
import amx from '@/Images/amx.png';
import {usePaymentMutation} from "@/App/Tickets/api";
import {PaymentPayload} from "@/App/Tickets/types";
import {useAppSelector} from "@/store";

const mobi_schema = z.object({
    name: z.string().min(1, 'Name is required'),
    phoneNumber: z
        .string()
        .min(9, 'Phone Number must be 9 digits')
        .max(9, 'Phone Number must be 9 digits'),
    payPhoneNumber: z
        .string()
        .min(9, 'Phone Number must be 9 digits')
        .max(9, 'Phone Number must be 9 digits'),
    email: z.string().email('Invalid email format').min(1, 'Email is required'),
});

export type MobileTicketInput = z.TypeOf<typeof mobi_schema>;

const card_schema = z.object({
    name: z.string().min(1, 'Name is required'),
    phoneNumber: z
        .string()
        .min(9, 'Phone Number must be 9 digits')
        .max(9, 'Phone Number must be 9 digits'),
    email: z.string().email('Invalid email format').min(1, 'Email is required'),
});

export type CardTicketInput = z.TypeOf<typeof card_schema>;


enum ModeType {
    CARD = 'card',
    MOBILE = 'mobile',
    NONE = 'none'
}

export const BuyTicket = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    const { user } = useAppSelector((state) => state.userState);

    const storedData = retrieveObjectFromLocalStorage('cartData');

    if(storedData == null) {
        return <Navigate to={`/event/${id}`} />;
    }

    const { event, ticketsData } = storedData!;

    const [mode, setMode] = useState<ModeType>(ModeType.NONE);
    const [mobi, setMobi] = useState(true);
    const [terms, setTerms] = useState(false);

    const [onPay, { isSuccess, isLoading }] = usePaymentMutation();

    useEffect(() => {
        if (isSuccess) {
            navigate('/success');
        }
    }, [isSuccess, navigate]);

    return (
        <div className=" flex flex-col justify-center items-center">
            <Header />

            <div className="flex max-w-[1200px] pt-5 w-full justify-center">
                <div className="flex w-full mx-5 flex-row gap-4">
                    <div className="w-full md:w-11/12 bg-Maincolor rounded-lg p-5 md:p-10 h-[180px] lg:h-[340px]">
                        <p className="header-font text-[30px] lg:text-[80px] font-extrabold text-white">
                            TICKET
                        </p>
                        <p className="header-font text-[30px] lg:text-[80px] font-extrabold text-white/60">
                            CHECKOUT
                        </p>

                        <div className="header flex flex-row text-[8px] sm:text-sm text-white gap-2 items-center">
                            <Link to={"/"} className={" uppercase hover:underline "}>HOME</Link>
                            <div className="text-lg">
                                <ChevronRightIcon className="text-white w-4" />
                            </div>{' '}
                            <Link to={`/event/${event?.id}`} className={" uppercase hover:underline "} >{event?.title}</Link>
                            <div className="text-lg">
                                <ChevronRightIcon className="text-white w-4" />
                            </div>{' '}
                            CHECKOUT
                        </div>
                    </div>
                    <div
                        style={{
                            background: `url('${banner}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                        className="hidden md:block w-36 h-[180px] lg:h-[340px] "
                    ></div>
                </div>
            </div>

            <div className="p-5 px-0 max-w-[1200px] w-11/12 flex md:flex-row flex-col gap-4 justify-center">
                <Form<MobileTicketInput, typeof mobi_schema>
                    schema={mobi_schema}
                    onSubmit={async (payload: any) => {
                        payload.ticket_number = parseInt(payload.ticket_number);
                        payload.phoneNumber = '250' + payload.phoneNumber;
                        payload.payPhoneNumber = '250' + payload.payPhoneNumber;
                        payload.payment_mode = mode === ModeType.MOBILE ? 'momo' : mode === ModeType.NONE ? '' : 'card';


                        const final_data = {
                            name: payload.name,
                            email: payload.email,
                            personalPhoneNumber: payload.phoneNumber,
                            phoneNumber: payload.payPhoneNumber,
                            payment_mode: payload.payment_mode,
                            pricingIds: ticketsData.map((data) => {
                                return {
                                    ticket_number: data.quantity,
                                    pricingId: data.ticket.id
                                }
                            })
                        } as PaymentPayload;

                        console.log(final_data);
                        mode === ModeType.NONE
                            ? toast.error('Please Select Payment Mode')
                            : payload.ticket_number == 0 ?
                                toast.error('Number of tickets Invalid')
                            : terms ? await onPay({ status: user != null, data: final_data })
                            : toast.error('Please accept terms and conditions.');
                    }}
                    className="w-full"
                >
                    {({ register, formState }) => {
                        return (
                            <div className="w-full flex flex-col md:flex-row gap-4">
                                <div className="md:w-2/3 z-50 flex flex-col">
                                    <div className="flex justify-start items-end">
                                        <h1 className="header-font text-[30px] m-4 mx-0 font-extrabold">
                                            Contact Information
                                        </h1>
                                    </div>
                                    <div className="gap-2 grid grid-cols-1">
                                        <div className="gap-2 grid grid-cols-1 sm:grid-cols-2">
                                            <InputField
                                                type="text"
                                                label="*Names"
                                                placeholder="Names"
                                                loading={isLoading}
                                                focus
                                                error={formState.errors.name}
                                                registration={register('name')}
                                            />
                                            <InputField
                                                type="tel"
                                                label="*Phone Number"
                                                placeholder="Phone"
                                                loading={isLoading}
                                                focus
                                                error={
                                                    formState.errors.phoneNumber
                                                }
                                                registration={register(
                                                    'phoneNumber'
                                                )}
                                            />
                                        </div>
                                        <div className="gap-2 grid grid-cols-1 ">
                                            <InputField
                                                type="text"
                                                label="*Email"
                                                placeholder="Email"
                                                loading={isLoading}
                                                focus
                                                error={formState.errors.email}
                                                registration={register('email')}
                                            />
                                        </div>
                                    </div>
                                    <p className={"text-sm font-bold mt-3"}>*Payment Mode</p>
                                    <div className="w-full gap-2 flex justify-start my-3 py-1 pb-0 px-1 rounded-xl border h-[60px] bg-gray-50 items-center">
                                        <div
                                            onClick={() => setMode(ModeType.MOBILE)}
                                            className={` p-2 flex flex-row gap-2 items-center transition-all rounded-b-0 rounded-lg ${
                                                mode === ModeType.MOBILE
                                                    ? 'border-Maincolor bg-Maincolor text-white border-2'
                                                    : 'cursor-pointer hover:bg-gray-200'
                                            }`}
                                        >
                                            <i className="fa fa-mobile" aria-hidden="true"></i>
                                            Mobile Payment
                                        </div>

                                        <div
                                            onClick={() => setMode(ModeType.CARD)}
                                            className={`p-2 border-b- flex flex-row items-center gap-2 transition-all rounded-lg ${
                                                mode === ModeType.CARD
                                                    ? 'border-Maincolor bg-Maincolor text-white border-2'
                                                    : 'cursor-pointer hover:bg-gray-200'
                                            }`}
                                        >
                                            <i className="fa fa-credit-card-alt" aria-hidden="true"></i>
                                            Card Payment
                                        </div>
                                    </div>
                                    {mode === ModeType.CARD ? (
                                        <div className="gap-2 transition-all duration-100 grid grid-cols-1">
                                            <div className="py-2 flex flex-row justify-center gap-2">
                                                <img src={visa} alt={"visa image"} className="w-12 h-10"/>
                                                <img src={masterCard} alt={"master card image"} className="w-12 h-8"/>
                                                <img src={wu} alt={"western union image"} className="w-12 h-10"/>
                                                <img src={amx} alt={"american express image"} className="w-15 h-10"/>
                                            </div>
                                            <div className="gap-2 grid grid-cols-1 sm:grid-cols-2">
                                                <InputField
                                                    type="text"
                                                    label="Names on Card"
                                                    placeholder="Names"
                                                    loading={isLoading}
                                                />

                                                <InputField
                                                    type="number"
                                                    label="Card Number"
                                                    placeholder="Card Number"
                                                    loading={isLoading}
                                                />
                                            </div>
                                            <div className="gap-2 grid grid-cols-2 sm:grid-cols-4">
                                                <InputField
                                                    type="text"
                                                    label="Expiry Date"
                                                    placeholder="mm/yyyy"
                                                    loading={isLoading}
                                                />

                                                <InputField
                                                    type="number"
                                                    label="CVV"
                                                    placeholder="CVV"
                                                    loading={isLoading}
                                                />
                                            </div>
                                        </div>
                                    ) : mode === ModeType.MOBILE ? (
                                        <div className="gap-2 transition-all duration-100 grid grid-cols-1">
                                            <div className="w-full sm:w-1/2 gap-4 flex justify-center p-4 items-center">
                                                <div
                                                    onClick={() => setMobi(true)}
                                                    className={` p-2 rounded-lg ${
                                                        mobi
                                                            ? 'shadow-md border-Maincolor border-2'
                                                            : 'cursor-pointer'
                                                    }`}
                                                >
                                                    <img
                                                        src={mtn}
                                                        alt="mtn momo"
                                                        className="w-[40px] h-[30px]"
                                                    />
                                                </div>

                                                <div
                                                    onClick={() => setMobi(false)}
                                                    className={`p-2 rounded-lg ${
                                                        mobi
                                                            ? 'cursor-pointer'
                                                            : 'shadow-md border-Maincolor border-2'
                                                    }`}
                                                >
                                                    <img
                                                        src={airtel}
                                                        alt="airel payment"
                                                        className="w-[40px] h-[30px]"
                                                    />
                                                </div>
                                            </div>
                                            <div className="gap-2 grid grid-cols-1 sm:grid-cols-2">
                                                <InputField
                                                    type="tel"
                                                    label="*Phone Number"
                                                    placeholder="Phone"
                                                    loading={isLoading}
                                                    focus
                                                    error={
                                                        formState.errors.payPhoneNumber
                                                    }
                                                    registration={register(
                                                        'payPhoneNumber'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>

                                {storedData == null ? (
                                    <div className="md:w-1/2 flex justify-center items-center">
                                        <div className="min-h-[600px] flex flex-row justify-center items-center">
                                            <CircularProgress
                                                className="text-Maincolor"
                                                size={24}
                                                sx={{color: '#8cc541'}}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="md:w-1/3">
                                        <div className="w-full">
                                            <div className="flex justify-start items-end">
                                                <h1 className="header-font text-[30px] m-4 mx-0 font-extrabold">
                                                    Summary
                                                </h1>
                                            </div>
                                        </div>
                                        <div className="p-5">
                                            <div className="flex flex-row items-center gap-2">
                                                <div
                                                    style={{
                                                        background: `url('${event?.image}')`,
                                                        backgroundPosition:
                                                            'center',
                                                        backgroundRepeat:
                                                            'no-repeat',
                                                        backgroundSize: 'cover',
                                                    }}
                                                    className="w-12 h-12 rounded-lg"
                                                ></div>
                                                <h1 className="text-[20px] font-extrabold text-Maincolor ">
                                                    {event?.title}
                                                </h1>
                                            </div>
                                            <div className="w-full h-[1px] my-4 bg-gray-600"></div>
                                            <div
                                                className="text-gray-600 mt-3 flex flex-row justify-between items-center gap-2">
                                                {' '}
                                                <div className="text-black font-bold">
                                                    Date
                                                </div>
                                                {' '}
                                                {new Date(
                                                    event?.date ?? ''
                                                ).toDateString()}
                                            </div>
                                            <div
                                                className="text-gray-600 mt-3 flex flex-row justify-between items-center gap-2">
                                                {' '}
                                                <div className="text-black font-bold">
                                                    Location
                                                </div>
                                                {' '}
                                                {event?.location}
                                            </div>
                                            <div className="w-full h-[1px] my-4 bg-gray-600"></div>
                                            {ticketsData.map((data, index) => (
                                                <div key={index} className="flex mt-3 flex-col gap-1 border-b pb-4 border-black">
                                                    <div
                                                        className="text-gray-600 flex flex-row justify-between items-center gap-2">
                                                        {' '}
                                                        <div className="text-black font-semibold">
                                                            Type
                                                        </div>
                                                        <div className="">{data.ticket.type}</div>
                                                    </div>
                                                    <div
                                                        className="text-gray-600 flex flex-row justify-between items-center gap-2">
                                                        {' '}
                                                        <div className="text-black font-semibold">
                                                            Price
                                                        </div>
                                                        <div
                                                            className="">{event?.currency} {data.ticket.price.toLocaleString()}</div>
                                                    </div>
                                                    <div
                                                        className="text-gray-600 flex flex-row justify-between items-center gap-2">
                                                        {' '}
                                                        <div className="text-black font-semibold">
                                                            Quantity
                                                        </div>
                                                        <div
                                                            className="">{data.quantity.toLocaleString()}</div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div
                                                className="text-gray-600 mt-3 flex flex-row justify-between items-center gap-2">
                                                {' '}
                                                <div className="text-black font-bold">
                                                    Total
                                                </div>
                                                {' '}
                                                <div className="">
                                                    {event?.currency}{' '}{calculateTotal(ticketsData).toLocaleString()}
                                                </div>

                                            </div>
                                            <div className="mt-5 w-full flex flex-row items-center gap-2 justify-center">
                                                <label className={"flex flex-row items-center gap-2"}>
                                                    <input
                                                        type="checkbox"
                                                        checked={terms}
                                                        className={"h-5 w-5 accent-Maincolor text-white"}
                                                        onChange={() => setTerms((prev) => !prev)}
                                                    />
                                                    I accept the <a className="underline text-Maincolor transition-all" href="/terms-and-conditions">Terms and Conditions.</a>
                                                </label>
                                            </div>
                                            <div className="flex justify-end items-center mt-2">
                                                {isLoading ? (
                                                    <button
                                                        type="submit"
                                                        className="bg-Maincolor w-full text-white font-bold py-2 px-4 rounded-2xl"
                                                        disabled
                                                    >
                                                        <CircularProgress
                                                            size={24}
                                                            sx={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="submit"
                                                        className="bg-Maincolor  w-full hover:shadow-xl text-white my-4 py-3 px-4 rounded-2xl"
                                                    >
                                                        Buy
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    }}
                </Form>
            </div>

            <EmailBox />

            <Footer />
        </div>
    );
};
