import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useWhoamiQuery } from '@/Auth/redux';
import { storage } from '@/utils';
import { useAppSelector } from '@/store';
import Dropdown from './Dropdown';
import IconUser from './Icon/IconUser';
import IconLogout from './Icon/IconLogout';
import toast from 'react-hot-toast';
import userLogo from '../Images/cate 2.jpg';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import logo from '../../src/Images/logo-1.png';
import {
    ArrowLeftOnRectangleIcon,
    ChevronDownIcon,
    TicketIcon,
    UserCircleIcon,
} from '@heroicons/react/24/outline';
import IconShoppingCart from './Icon/IconShoppingCart';
import { FaBars, FaHamburger } from 'react-icons/fa';
import SelectInput from '@mui/material/Select/SelectInput';

function NavItem({ title, value }: { title: string; value: string }) {
    return (
        <div>
            <NavLink
                to={`/${value}`}
                style={({ isActive }) =>
                    isActive
                        ? {
                              textDecoration: 'none',
                              color: '#8cc541',
                          }
                        : {}
                }
                className="block font-semibold text-gray-900 text-xs hover:text-Maincolor transition-all"
            >
                {title}
            </NavLink>
        </div>
    );
}

function AccItem() {
    const [open, setOpen] = useState(false);

    return (
        <div className="text-sm font-semibold">
            <div
                onClick={() => setOpen(m => !m)}
                className="border w-24 relative cursor-pointer p-2 flex gap-2 flex-row item-center border-Maincolor/20 font-semibold text-gray-900 text-xs"
            >
                ACCOUNT
                <ChevronDownIcon className="w-3" />
            </div>

            {open && (
                <div onMouseLeave={() => setOpen(false)} className="absolute w-24 bg-white mt-1 flex flex-col">
                    <Link
                        to="/login"
                        className="border justify-center border-b-0 hover:text-white hover:bg-Maincolor cursor-pointer p-2 flex gap-2 flex-row item-center border-Maincolor/20 font-semibold text-gray-900 text-xs"
                    >
                        LOG IN
                    </Link>
                    <Link
                        to="/signup"
                        className="border justify-center hover:text-white hover:bg-Maincolor cursor-pointer p-2 flex gap-2 flex-row item-center border-Maincolor/20 font-semibold text-gray-900 text-xs"
                    >
                        SIGN UP
                    </Link>
                </div>
            )}
        </div>
    );
}

function AccLink() {
    return (
        <div className="text-sm font-semibold">
            <NavLink
                style={({ isActive }) =>
                    isActive
                        ? {
                              color: '#8cc541',
                          }
                        : {}
                }
                to={'/account'}
                className="border w-24 relative cursor-pointer p-2 flex gap-2 flex-row justify-center item-center border-Maincolor/20 font-semibold text-gray-900 text-xs"
            >
                ACCOUNT
            </NavLink>
        </div>
    );
}

const Header = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const { isLoading, data } = useWhoamiQuery(null, {
        skip: !storage.getToken(),
        refetchOnMountOrArgChange: true,
    });

    const { user } = useAppSelector((state: any) => state.userState);

    const [open, setOpen] = useState(true);

    return (
        <div className=" w-full py-3 shadow-sm sticky flex flex-col sm:flex-row justify-between items-center bg-white">
            <div className="flex w-full sm:w-auto flex-row justify-between items-center">
                <a href="/" className="flex flex-row gap-1 mx-10">
                    <img src={logo} alt="logo" className="" />
                </a>

                <div
                    onClick={() => setOpen(t => !t)}
                    className="bg-Maincolor sm:hidden rounded-full mr-4 my-2 text-white p-2"
                >
                    <FaBars />
                </div>
            </div>

            <ul
                className={`p-3 ${
                    open ? 'hidden' : 'flex'
                } sm:flex gap-3 sm:w-auto w-full items-center flex-col sm:flex-row justify-around`}
            >
                <div className="flex flex-col text-center sm:flex-row sm:items-center gap-4 pr-5">
                    <NavItem title="HOME" value="" />
                    <NavItem title="EVENTS" value="events" />
                    <NavItem title="CONTACT US" value="contact-us" />
                    {isLoading ? (
                        <div className="w-10"></div>
                    ) : user != null ? (
                        <AccLink />
                    ) : (
                        <AccItem />
                    )}
                </div>
            </ul>
        </div>
    );
};
export default Header;

function MyTicketsButton() {
    const { user } = useAppSelector((state: any) => state.userState);
    const navigate = useNavigate();

    const logout = () => {
        storage.removeToken();
        location.href = '/';
        toast.error('You logged out');
    };

    return (
        <div className="flex flex-row gap-1">
            <div className="dropdown shrink-0 flex">
                <Dropdown
                    offset={[0, 8]}
                    btnClassName="relative group block"
                    button={
                        <div className="w-9 h-9 rounded-full object-cover saturate-50   group-hover:saturate-100">
                            <UserCircleIcon className="text-Maincolor" />
                        </div>
                    }
                >
                    <ul className="text-dark dark:text-white-dark !py-0 w-[230px] font-semibold bg-white shadow rounded  dark:text-white-light/90">
                        <li>
                            <div className="flex items-center px-4 py-4">
                                <img
                                    className="rounded-md w-10 h-10 object-cover"
                                    src={userLogo}
                                    alt="userProfile"
                                />
                                <div className="ltr:pl-3 rtl:pr-3 truncate flex flex-col gap-1">
                                    <h4 className="text-base flex flex-row justify-start gap-1">
                                        {user && user?.username}
                                        <span className="text-[10px] px-1 py-[2px] bg-cyan-300 text-white">
                                            {' '}
                                            {user && user?.role}
                                        </span>
                                    </h4>
                                    <button
                                        type="button"
                                        className="text-black/60 text-xs text-ellipsis hover:text-primary dark:text-dark-light/60 dark:hover:text-white"
                                    >
                                        {user && user?.email}
                                    </button>
                                </div>
                            </div>
                        </li>
                        <li>
                            <Link
                                to="/account"
                                className="dark:hover:text-white flex flex-row "
                            >
                                <TicketIcon className="w-5 h-5 mx-4 " />
                                My Tickets
                            </Link>
                        </li>
                        <li
                            className="border-t border-white-light dark:border-white-light/10"
                            onClick={logout}
                        >
                            <Link
                                to="/auth/boxed-signin"
                                className="text-danger  flex flex-row justify-start py-2  "
                            >
                                <IconLogout className="w-5 h-5 mx-4 " />
                                Sign Out
                            </Link>
                        </li>
                    </ul>
                </Dropdown>
            </div>
        </div>
    );
}
