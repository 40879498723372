import baseApi from "@/core/lib/base";
import {EventModel, EventResponseModel} from "@/App/Events/types";
import { eventsSlice } from "@/App/Events/redux";

export const eventApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getEvents: builder.query<EventResponseModel,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                search?: string;
                categoryId?: string;
                role?: string;
                status?: string;
                location?: string;
                eventId?: string;
            }
        >({
            query: (queryParameters) => {
                const {
                    pageNumber,
                    pageSize,
                    from,
                    to,
                    search,
                    status,
                    role,
                    eventId,
                    location,
                    categoryId,
                } = queryParameters;
                return {
                    method: "GET",
                    url: "/api/v1/event/available",
                    params: {
                        pageNumber,
                        pageSize,
                        from,
                        to,
                        role,
                        status,
                        location,
                        eventId,
                        search,
                        categoryId,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(eventsSlice.actions.setEvents(data));
                } catch (error) {
                    throw error;
                }
            },
        }),

        getPastEvents: builder.query<EventResponseModel,
            {
                pageNumber?: number;
                pageSize?: number;
                location?: string;
                categoryId?: string;
            }
        >({
            query: (queryParameters) => {
                const {
                    pageNumber,
                    pageSize,
                    location,
                    categoryId,
                } = queryParameters;
                return {
                    method: "GET",
                    url: "/api/v1/event/past-events",
                    params: {
                        pageNumber,
                        pageSize,
                        location,
                        categoryId,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(eventsSlice.actions.setPastEvents(data));
                } catch (error) {
                    throw error;
                }
            },
        }),

        getSingleEvent: builder.query<EventModel,
            {
                slug: string;
            }
        >({
            query: (queryParameters) => {
                const { slug } = queryParameters;
                return {
                    method: "GET",
                    url: `/api/v1/event/${slug}`,
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(eventsSlice.actions.setSingleEvent(data));
                } catch (error) {
                    throw error;
                }
            },
        }),

        getEventLocations: builder.query<string[], {}>({
            query: () => {
                return {
                    method: "GET",
                    url: "/api/v1/event/locations",
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(eventsSlice.actions.setEventLocations(data));
                } catch (error) {
                    throw error;
                }
            },
        }),
    })
});

export const { useGetEventsQuery, useGetPastEventsQuery, useGetSingleEventQuery, useGetEventLocationsQuery } = eventApi;
