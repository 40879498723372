import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {MyTicketsResponseModel} from "@/App/Tickets/types";
import {EventModel, EventResponseModel} from "@/App/Events/types";

interface InitialState {
    events: EventResponseModel | null,
    pastEvents: EventResponseModel | null,
    singleEvent: EventModel | null,
    eventLocations: string[],
}

const initialState: InitialState = {
    events: null,
    pastEvents: null,
    singleEvent: null,
    eventLocations: []
};

export const eventsSlice = createSlice({
    initialState,
    name: 'eventsSlice',
    reducers: {
        setEvents: (state, action: PayloadAction<EventResponseModel>) => {
            state.events = action.payload;
        },
        setPastEvents: (state, action: PayloadAction<EventResponseModel>) => {
            state.pastEvents = action.payload;
        },
        setSingleEvent: (state, action: PayloadAction<EventModel>) => {
            state.singleEvent = action.payload;
        },
        setEventLocations: (state, action: PayloadAction<string[]>) => {
            state.eventLocations = action.payload;
        },
    },
});
