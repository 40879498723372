import baseApi from "@/core/lib/base";
import {MySingleTicketModel, MyTicketsResponseModel, PaymentPayload} from "@/App/Tickets/types";
import toast from "react-hot-toast";
import {storage} from "@/utils";
import {myTicketsSlice} from "@/App/Tickets/redux";

export const myTicketsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getMyTickets: builder.query<MyTicketsResponseModel,
            {
                pageNumber?: number;
                pageSize?: number;
            }
        >({
            query: (queryParameters) => {
                const {
                    pageNumber,
                    pageSize,
                } = queryParameters;
                return {
                    method: "GET",
                    url: "/api/v1/ticket/my-tickets",
                    params: {
                        pageNumber,
                        pageSize,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(myTicketsSlice.actions.setMyTickets(data));
                } catch (error: any) {
                    if(error.error.status === 401) {
                        storage.removeToken();
                        window.location.href = '/login'
                    }
                    throw error;
                }
            },
        }),

        getSingleTicket: builder.query<MySingleTicketModel,
            {
                id: string;
            }
        >({
            query: (queryParameters) => {
                const {
                    id
                } = queryParameters;
                return {
                    method: "GET",
                    url: `/api/v1/ticket/${id}`,
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(myTicketsSlice.actions.setSingleTickets(data));
                } catch (error: any) {
                    if(error.error.status === 401) {
                        storage.removeToken();
                        window.location.href = '/login'
                    }
                    throw error;
                }
            },
        }),



        payment: builder.mutation<MyTicketsResponseModel, {  status: boolean, data: PaymentPayload }>({
            query: (payload) => {
                console.log(payload);
                return {
                    method: "POST",
                    url: `/api/v1/payment${payload.status ? '/bulk' : '/guest/bulk'}`,
                    body: payload.data
                };
            },
            onQueryStarted: async (_args, { queryFulfilled }) => {
                try {
                    await queryFulfilled;
                } catch (error: any) {
                    if (error.error.status === 401) {
                        toast.error("Payment Failed! Log In again");
                        storage.removeToken();

                        // Delay redirection by 5 seconds
                        setTimeout(function() {
                            window.location.href = '/login';
                        }, 3000); // 5000 milliseconds = 5 seconds
                    }
                    toast.error("Payment Failed!");
                    throw error;
                }
            },
        }),

    }),
});

export const { useGetMyTicketsQuery, usePaymentMutation, useGetSingleTicketQuery } = myTicketsApi;
