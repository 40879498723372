import React, {useEffect, useState} from 'react';
import formatDateToLongForm, {currencyWithAmount} from '@/utils/DateFormattter';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import {CircularProgress} from '@mui/material';
import banner from '../../../Images/banner.png';
import {
    BuildingOffice2Icon,
    CalendarDaysIcon,
    ChevronRightIcon,
    MapIcon,
    MapPinIcon,
    PhoneIcon
} from '@heroicons/react/24/outline';
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {useGetEventsQuery, useGetSingleEventQuery} from "@/App/Events/api";
import {Ticket} from "@/App/Events/types";
import {retrieveObjectFromLocalStorage, setDocumentMetaDescription, storeObjectInLocalStorage} from "@/utils/storage";
import toast from "react-hot-toast";

export interface CheckDataType { ticket: Ticket, quantity: number }

const updateQuantity = (
    prev: CheckDataType[],
    index: number,
    delta: number
): { ticket: Ticket; quantity: number }[] => {
    const updatedData = [...prev];
    const targetTicket = updatedData[index];

    if (targetTicket) {
        const newQuantity = Math.max(0, targetTicket.quantity + delta);

        updatedData[index] = {
            ticket: targetTicket.ticket,
            quantity: newQuantity,
        };
    }

    return updatedData;
};

export const calculateTotal = (cat: CheckDataType[]) => {
    return cat.map((item) => item.quantity * item.ticket.price).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

export default function EventDesc() {
    const { id } = useParams();

    const navigate = useNavigate();

    const { isLoading, data } = useGetSingleEventQuery(
        { slug: id ?? '' },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const event = data;

    const [checkData, setCheckData] = useState<CheckDataType[]>([]);

    useEffect(() => {
        let dt : { ticket: Ticket, quantity: number }[] = [];

        if(data && data?.pricings) {
            dt = data?.pricings.map((tick) => {
                return { ticket: tick, quantity: 0 } as CheckDataType;
            });
        }

        setCheckData(dt);

    }, [data]);

    useEffect(() => {
        if(event) {
            document.title = `RG Tickets | ${event?.title}`;
            setDocumentMetaDescription(event?.description)
        }
    }, [event]);

    const handleIncrementClick = (index: number) => {
        setCheckData((prev) => updateQuantity(prev, index, 1));
    };

    const handleDecrementClick = (index: number) => {
        setCheckData((prev) => updateQuantity(prev, index, -1));
    };

    const handlePayNow = () => {
        const alpha = {
            event: event,
            ticketsData: checkData.filter((item) => item.quantity > 0),
        }

        if(alpha.ticketsData.length === 0) {
            toast.error('Please select tickets.');
        } else {
            storeObjectInLocalStorage('cartData', alpha)

            navigate(`/event-buy/${event?.id}`);
        }
    }

    return (
        <main className="flex bg-gray-100 justify-center items-center flex-col">
            <Header />

            {event && !isLoading ? (
                <div className="w-full bg-gray-100 py-4 min-h-screen flex flex-col items-center justify-start my-4 ">
                    <div className="flex w-full justify-center">
                        <div className="flex w-11/12 mb-5 flex-row gap-4">
                            <div
                                style={{
                                    background: `url('${event.image}')`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                }}
                                className=" w-full md:w-11/12 bg-Maincolor overflow-hidden rounded-lg h-[240px] lg:h-[340px]"
                            >
                                <div className="dark-bg flex-col flex justify-between p-5 md:p-10">
                                    <p className="header-font text-[30px] sm:text-[40px] lg:text-[60px] font-extrabold text-white">
                                        {event.title}
                                    </p>

                                    <div className="flex flex-row text-sm text-white gap-2 items-center">
                                        <Link to={"/"} className={" uppercase hover:underline "} >HOME</Link>
                                        <div className="text-lg">
                                            <ChevronRightIcon className="text-white w-4" />
                                        </div>{' '}
                                        {event.title}
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    background: `url('${banner}')`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                }}
                                className="hidden md:block w-36 h-[240px] lg:h-[340px] "
                            ></div>
                        </div>
                    </div>
                    <div className=" w-11/12 flex justify-center items-center dark:bg-gray-800 rounded-lg overflow-hidden">
                        <div className="w-full justify-between items-start flex flex-col sm:flex-row gap-5 ">
                            <div className="w-full min-h-[464px] bg-white p-5 md:w-2/3">
                                <h3 className="text-xl text-center border-b pb-3 font-normal text-gray-600 dark:text-white">
                                    Event Tickets
                                </h3>
                                {/*{event && event.pricings.length === 0 && <p className='text-center mt-4 w-full flex justify-center items-center'>No Tickets Available.</p>}*/}
                                {event.pricings && event.pricings.length != 0 ? <>
                                    <div className="w-full flex flex-col md:flex-row gap-1 justify-between">
                                        <div className="grid w-full md:w-1/2 mt-4 grid-cols-1 gap-4">
                                            {event.pricings.map((pricings: any, index: number) => (
                                                <div key={pricings.id} className="flex gap-3 flex-row">
                                                    <div
                                                        className="rounded-md w-1/2"
                                                    >
                                                        <div
                                                            className="border border-gray-200 overflow-hidden flex flex-col gap-1 dark:border-gray-700 p-4 rounded-md">
                                                            <div className="">
                                                                <p className="text-sm font-bold text-center ">
                                                                    CATEGORY
                                                                </p>
                                                                <p className="text-Maincolor -translate-y-2 text-2xl text-center font-[600] ">
                                                                    {pricings.type}
                                                                </p>
                                                            </div>

                                                            <button
                                                                className="bg-Maincolor -translate-y-2.5 border-Maincolor text-white px-3 rounded-xl py-1"
                                                            >
                                                                {pricings.price.toLocaleString()} {event.currency}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="w-1/2 border items-center border-gray-200 overflow-hidden flex flex-col gap-1 dark:border-gray-700 p-4 rounded-md">
                                                        <div className="text-md gap-0 max-w-[100px] font-bold text-center ">
                                                            <p className={"p-0"}>NUMBER</p>
                                                            <p className={"p-0 -translate-y-2"}>OF TICKETS</p>
                                                        </div>

                                                        <div className="flex -translate-y-2.5 flex-row justify-center gap-1">
                                                            <div className="bg-gray-200 w-2/5 rounded-xl overflow-clip">
                                                                <input readOnly={true}
                                                                       value={checkData && checkData.length != 0 ? checkData[index].quantity : 0}
                                                                       type={"number"}
                                                                       className={"bg-transparent w-full outline-none px-2 text-center text-Maincolor font-bold py-1 rounded-lg"}/>
                                                            </div>

                                                            <div
                                                                className="bg-gray-200 overflow-clip items-center text-[7px] text-Maincolor justify-center w-1/5 rounded-xl flex flex-col">
                                                                <button onClick={() => handleIncrementClick(index)}
                                                                        className="hover:bg-gray-500 hover:text-white cursor-pointer w-full flex items-center justify-center py-1">
                                                                    <FaChevronUp/>
                                                                </button>

                                                                <button
                                                                    onClick={() => handleDecrementClick(index)}
                                                                    className="hover:bg-gray-500 hover:text-white cursor-pointer w-full flex items-center justify-center py-1">
                                                                    <FaChevronDown/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="w-full md:w-1/2 flex flex-col justify-start items-center gap-10 pt-4 ">
                                            <div
                                                className=" w-[60%] max-w-[230px] border pt-5 border-gray-200 overflow-hidden flex flex-col gap-2 dark:border-gray-700 p-4 rounded-md">
                                                <p className="text-sm font-bold text-center ">
                                                    YOUR TICKETS
                                                </p>

                                                <div className="flex flex-col mt-4 w-full items-center gap-1">
                                                    {checkData && checkData.length != 0 ? checkData.map((itm, index) => {
                                                        if (itm.quantity > 0) {
                                                            return (<div key={index}
                                                                         className="flex transition-all flex-row items-center text-sm gap-2">
                                                                <div
                                                                    className="bg-Maincolor py-2 text-start flex justify-start flex-row text-white min-w-[100px] rounded-xl">
                                                                    <div className="text-start mx-5 flex flex-row justify-start">{itm.ticket.type}</div>
                                                                </div>
                                                                <div
                                                                    className="px-1 py-0 h-5 bg-gray-100 w-10 gap-1 rounded-md border flex items-center justify-center text-center text-[10px]">{itm.quantity}{' '}
                                                                    pc{itm.quantity > 1 ? 's' : ''}
                                                                </div>
                                                            </div>);
                                                        }
                                                    }) : null}

                                                    <div className="mt-10 flex flex-row items-center gap-2 text-Maincolor font-bold">
                                                        <div className="bg-gray-100 px-3 py-1 text-Maincolor font-bold min-w-[90px] text-center rounded-xl">
                                                            {calculateTotal(checkData).toLocaleString()}
                                                        </div>
                                                        {event.currency}
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                onClick={() => handlePayNow()}
                                                className="rounded-2xl max-w-[230px] text-lg bg-Maincolor text-white cursor-pointer py-2 px-3 w-[63%] text-center">Pay
                                                Now
                                            </button>
                                        </div>
                                    </div>
                                </> : null}
                            </div>

                            <div className="w-full bg-white p-5 sm:w-1/3">
                                <h3 className="text-xl text-center border-b pb-3 font-normal text-gray-600 dark:text-white">
                                    Event Details
                                </h3>
                                <div className="py-4 px-2 items-center flex flex-row">
                                    <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                        <CalendarDaysIcon/>
                                    </div>
                                    <div className="px-3">
                                        <p className='text-gray-500 text-sm'>Start Date</p>
                                        <p>{formatDateToLongForm(event.date)}</p>
                                    </div>
                                </div>
                                {data?.endDate && <div className="py-4 px-2 items-center flex flex-row">
                                    <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                        <CalendarDaysIcon/>
                                    </div>
                                    <div className="px-3">
                                        <p className="text-gray-500 text-sm">
                                            End Date
                                        </p>
                                        <p>
                                            {formatDateToLongForm(
                                                data?.endDate
                                            )}
                                        </p>
                                    </div>
                                </div>}
                                <div className="py-4 px-2 items-center flex flex-row">
                                    <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                        <MapIcon/>
                                    </div>
                                    <div className="px-3">
                                        <p className='text-gray-500 text-sm'>Country</p>
                                        <p>{event.country}</p>
                                    </div>
                                </div>
                                <div className="py-4 px-2 items-center flex flex-row">
                                    <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                        <MapPinIcon/>
                                    </div>
                                    <div className="px-3">
                                        <p className='text-gray-500 text-sm'>Location</p>
                                        <p>{event.location}</p>
                                    </div>
                                </div>
                                <div className="py-4 px-2 items-center flex flex-row">
                                    <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                        <BuildingOffice2Icon/>
                                    </div>
                                    <div className="px-3">
                                        <p className='text-gray-500 text-sm'>Event Organizer</p>
                                        <p>{event.organiser}</p>
                                    </div>
                                </div>
                                <div className="py-4 px-2 items-center flex flex-row">
                                    <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                        <PhoneIcon/>
                                    </div>
                                    <div className="px-3">
                                        <p className='text-gray-500 text-sm'>Contacts</p>
                                        <p>{event.phoneNumber}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="min-h-[600px] flex flex-row justify-center items-center">
                    <CircularProgress
                        className="text-Maincolor"
                        size={24}
                        sx={{color: '#8cc541'}}
                    />
                </div>
            )}
            <Footer/>
        </main>
    );
}
