import { useEffect, useState } from 'react';
import z from 'zod';
import { useNavigate } from 'react-router-dom';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import toast from 'react-hot-toast';
import config from '@/config';
import {useAppSelector} from "@/store";

const schema = z.object({
    name: z.string().min(1, 'Name is required'),
    username: z.string().min(1, 'Username is required'),
    email: z.string().min(1, 'Email is required'),
    phoneNumber: z
        .string()
        .min(12, 'Phone Number must be at least 10 numbers, start by 250')
        .max(12, 'Phone Number must be at most 10 numbers , stat by 250'),
    password: z.string().min(1, 'Password is  required'),
});

export type SignUpInput = z.TypeOf<typeof schema>;

function useSignUp() {
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (payload: any) => {
        try {
            setLoading(true);
            console.log(payload);
            const response = await fetch(
                `${config.API_URL}/api/v1/user/register`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                }
            );
            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message.toString());
            }
            const result = await response.json();
            toast.success('Account Created! Login.');
            navigate('/login');
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setLoading(false);
        }
    };

    return {
        isLoading,
        onSubmit,
    };
}

export const SignUp = () => {
    const { isLoading, onSubmit } = useSignUp();
    const { user } = useAppSelector((state) => state.userState);

    useEffect(() => {
        if(user) {
            window.location.href = '/';
        }
    }, [user]);

    return (
        <>
            <main className="Container">
                <Header />

                <div className="w-full ">
                    <div className="w-11/12 max-w-[1000px] flex flex-row mx-auto shadow-md mt-10">
                        <div
                            style={{
                                background: `url('https://wallpapercave.com/wp/wp2349397.jpg')`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                            }}
                            className="w-1/2 hidden md:block"
                        >
                            <div className="w-full flex backdrop-blur-md flex-col justify-center items-center dark-bg p-10">
                                <p className="text-[40px] text-white font-bold">
                                Join Us Today!
                                </p>
                                <p className="text-[20px] text-white">
                                Create your account to unlock a world of exclusive benefits and features.
                                </p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 p-5">
                            <h2 className="font-black text-[30px] lg:text-[40px] text-Maincolor text-center">
                                SignUp
                            </h2>
                            <Form
                                schema={schema}
                                onSubmit={(payload: any) => onSubmit(payload)}
                                className="mt-10"
                            >
                                {({ register, formState }) => (
                                    <>
                                        <div className="space-y-4">
                                            <div className="grid sm:grid-cols-2 gap-2">
                                                <InputField
                                                    type="text"
                                                    label="Name"
                                                    placeholder="Enter Name"
                                                    loading={isLoading}
                                                    focus
                                                    error={formState.errors.name}
                                                    registration={register('name')}
                                                    className="h-13"
                                                />

                                                <InputField
                                                    type="text"
                                                    label="Username"
                                                    placeholder="Enter username"
                                                    loading={isLoading}
                                                    focus
                                                    error={
                                                        formState.errors.username
                                                    }
                                                    registration={register(
                                                        'username'
                                                    )}
                                                    className="h-13"
                                                />

                                            </div>
                                            <div className="grid sm:grid-cols-2 gap-2">
                                                <InputField
                                                    type="text"
                                                    label="Email"
                                                    placeholder="Enter Email"
                                                    loading={isLoading}
                                                    focus
                                                    error={formState.errors.email}
                                                    registration={register('email')}
                                                    className="h-13"
                                                />
                                                <InputField
                                                    type="text"
                                                    label="Phone Number"
                                                    placeholder="Enter Phone ex: 2507......."
                                                    loading={isLoading}
                                                    focus
                                                    error={
                                                        formState.errors.phoneNumber
                                                    }
                                                    registration={register(
                                                        'phoneNumber'
                                                    )}
                                                    className="h-13"
                                                />

                                            </div>

                                            <InputField
                                                type="password"
                                                label="Password"
                                                placeholder="********"
                                                loading={isLoading}
                                                error={
                                                    formState.errors.password
                                                }
                                                registration={register(
                                                    'password'
                                                )}
                                                className="h-13"
                                            />
                                        </div>
                                        {isLoading ? (
                                            <button className="w-full h-12 font-black pt-3 bg-[#8CC541] text-center content-center666666 flex justify-center uppercase text-[white] rounded-lg mt-10">
                                                <CircularProgress
                                                    size={24}
                                                    sx={{ color: 'white' }}
                                                />
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="w-full h-12 font-black pt-3 bg-[#8CC541] text-center content-center666666 flex justify-center uppercase text-[white] rounded-lg mt-10"
                                            >
                                                Sign In
                                            </button>
                                        )}
                                    </>
                                )}
                            </Form>

                            <div className="p-2 flex mt-10 gap-2 justify-center items-center">
                                Already have an account?{' '}
                                <a
                                    className="text-Maincolor font-semibold text-xl"
                                    href="/login"
                                >
                                    Log In
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    );
};

export default SignUp;
