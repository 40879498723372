import Footer from '@/components/Footer';
import Header from '@/components/Header';
import React, { useEffect, useState } from 'react';
import {EventCard, EventLoader} from "@/components/appComponents";
import { FaSearch } from 'react-icons/fa';
import {useGetEventLocationsQuery, useGetEventsQuery} from "@/App/Events/api";
import {useGetAllCategoriesQuery} from "@/App/categories/api";
import {CircularProgress} from "@mui/material";

export const Events = () => {
    const [cateParam, setCatParam ] = useState('');
    const [queryParam, setQueryParam] = useState('');
    const [selectedLoc, setSelectedLoc] = useState('');


    const { isFetching, data } = useGetEventsQuery(
        { pageNumber: 1, pageSize: 10, search: queryParam, categoryId: cateParam, location: selectedLoc },
        {

            refetchOnMountOrArgChange: true,
        }
    );

    const categories = useGetAllCategoriesQuery({});
    const locations = useGetEventLocationsQuery({});

    const arr: any[] = [1,1,1];


  const updateQueryParam = (newParamValue: string) => {
    const params = new URLSearchParams(window.location.search);
    params.set('search', newParamValue);
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.pushState({}, '', newUrl);
    setQueryParam(newParamValue);
  };

    const updateQueryParamCategory = (newParamValue: string) => {
        const params = new URLSearchParams(window.location.search);
        params.set('category', newParamValue);
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.pushState({}, '', newUrl);
        setCatParam(newParamValue);
    };

    const updateQueryParamLocation = (newParamValue: string) => {
        const params = new URLSearchParams(window.location.search);
        params.set('location', newParamValue);
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.pushState({}, '', newUrl);
        setSelectedLoc(newParamValue);
    };


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get('search');
    const paramValue2 = params.get('category');
    const paramValue3 = params.get('location');

    if (paramValue) {
      setQueryParam(paramValue);
    }
      if (paramValue2) {
          setCatParam(paramValue2);
      }

      if (paramValue3) {
          setSelectedLoc(paramValue3);
      }

  }, []);

    return (
        <main className="">
            <Header />

            <div className="w-full min-h-[600px] bg-gray-100 py-10">
                <div className="w-full justify-center flex flex-row mb-5">
                    <div className="p-4 justify-center items-center gap-2 flex flex-row w-full lg:w-3/5">
                        <input
                            onChange={(e) => updateQueryParam(e.target.value)}
                            className="w-full p-3 shadow-md outline-none rounded-full"
                            type="search"
                            name="search"
                            id="search"
                        />
                        <div className="bg-Maincolor cursor-pointer p-5 rounded-full">
                            <FaSearch className="text-white" />
                        </div>
                    </div>
                </div>
                <div className="w-10/12 max-w-[1200px] min-h-full mx-auto">
                    <div className="flex w-full flex-wrap justify-between items-center">
                        <h1 className=" text-[15px] lg:text-[25px] font-bold">
                            {queryParam.length === 0 ? 'Discover events' : `Results for ' ${queryParam} '`}
                        </h1>

                        <div className="flex flex-wrap gap-2 items-center">
                            <select onChange={(e) => {
                                updateQueryParamLocation(e.target.value);
                                setSelectedLoc(e.target.value);
                            }}
                                    className="bg-gray-50 px-3 py-2 border border-gray-300 rounded-lg capitalize">
                                <option value={''}>Location</option>
                                {!locations.isLoading && locations.data ? locations.data.map((location, index) => (
                                    <option value={location} className={"capitalize"} key={index}>{location}</option>
                                )) : <>loading</>}
                            </select>
                            <select onChange={(e) => updateQueryParamCategory(e.target.value)}
                                    className="bg-gray-50 px-3 py-2 border border-gray-300 rounded-lg capitalize">
                                <option value={''}>Category</option>
                                {categories.data ? categories.data.map((category, index) => (
                                    <option value={category.id} key={index}>{category.name}</option>
                                )) : <>loading</>}
                            </select>
                        </div>
                    </div>
                    {data?.list.length == 0 ?
                        <div className="p-10 flex justify-center items-center h-32">No events Available</div> : <div
                            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2   lg:grid-cols-3 xl:grid-cols-3 w-full gap-16 justify-between min-h-[300px] mt-5">
                            {isFetching ? (
                                <div className="min-h-[400px] col-span-3 flex flex-row justify-center items-center">
                                    <CircularProgress
                                        className="text-Maincolor"
                                        size={24}
                                        sx={{color: '#8cc541'}}
                                    />
                                </div>
                            ) : data?.list.map((event) => (
                                <EventCard key={event.id} event={event}/>
                            ))}
                        </div>}
                </div>
            </div>

            <Footer/>
        </main>
    );
};
