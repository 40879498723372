import { useEffect } from 'react';
import z from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { useLoginMutation } from '../redux';
import {useAppSelector} from "@/store";

const schema = z.object({
    username: z.string().min(1, 'Username or Email is required'),
    password: z.string().min(1, 'Password is  required'),
});

export type LoginInput = z.TypeOf<typeof schema>;

export const Login = () => {
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.userState);
    const [onLogin, { isSuccess, isLoading, data }] = useLoginMutation();

    if(user) {
        window.location.href = '/';
    }

    useEffect(() => {
        if (isSuccess) {
            navigate('/');
        }
    }, [isSuccess, navigate]);
    return (
        <>
            <main className="Container">
                <Header />

                <div className="w-full">
                    <div className="w-11/12 max-w-[1000px] flex flex-row mx-auto shadow-md mt-10">
                        <div
                            style={{
                                background: `url('https://wallpapercave.com/wp/wp2349397.jpg')`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                            }}
                            className="w-1/2 hidden md:block"
                        >
                            <div className="w-full flex backdrop-blur-md flex-col justify-center items-center dark-bg p-10">
                                <p className='text-[40px] text-white font-bold'>Welcome Back,</p>
                                <p className='text-[20px] text-white'>Sign in to your account to access all the features and services we offer.</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 p-5">
                            <h2 className="font-black text-[30px] lg:text-[40px] text-Maincolor text-center">
                                Login
                            </h2>
                            <Form
                                schema={schema}
                                onSubmit={(payload: any) => onLogin(payload)}
                                className="mt-10"
                            >
                                {({ register, formState }) => (
                                    <>
                                        <div className="space-y-4">
                                            <InputField
                                                type="text"
                                                label="Username"
                                                placeholder="Username"
                                                loading={isLoading}
                                                focus
                                                error={
                                                    formState.errors.username
                                                }
                                                registration={register(
                                                    'username'
                                                )}
                                                className="h-13"
                                            />

                                            <InputField
                                                type="password"
                                                label="Password"
                                                placeholder="********"
                                                loading={isLoading}
                                                error={
                                                    formState.errors.password
                                                }
                                                registration={register(
                                                    'password'
                                                )}
                                                className="h-13"
                                            />

                                            <div className="flex flex-col  ">
                                                <div className="flex flex-row items-center  justify-between">
                                                    <Link
                                                        to="/reset-password"
                                                        className="text-sm font-medium text-primary"
                                                    >
                                                        Forgot password?
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <button className="w-full h-12 font-black pt-3 bg-[#8CC541] text-center content-center666666 flex justify-center uppercase text-[white] rounded-lg mt-10">
                                                <CircularProgress
                                                    size={24}
                                                    sx={{ color: 'white' }}
                                                />
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="w-full h-12 font-black pt-3 bg-[#8CC541] text-center content-center666666 flex justify-center uppercase text-[white] rounded-lg mt-10"
                                            >
                                                Log In
                                            </button>
                                        )}
                                    </>
                                )}
                            </Form>

                            <div className="p-2 flex flex-wrap mt-10 gap-2 justify-center items-center">
                                Don't have an account?{' '}
                                <a
                                    className="text-Maincolor font-semibold text-xl"
                                    href="/signup"
                                >
                                    Sign Up
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </>
    );
};

export default Login;
