import clsx from "clsx";
import { UseFormRegisterReturn } from "react-hook-form";
import { FieldWrapper, FieldWrapperPassThroughProps } from "../fieldwrapper";
import React, { useState } from "react";
import {BI, DJ, ET, FlagComponent, KE, RW, TZ, UG, US} from "country-flag-icons/react/1x1";
import {ChevronDownIcon} from "@heroicons/react/24/outline";
import {ChevronUpIcon} from "@heroicons/react/20/solid";

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: "text" | "number" | "email" | "password" | "date" | "tel";
  placeholder?: string;

  focus?: boolean;
  onChange?: (value: any) => void; // Define onChange as a function that takes a string value
  loading?: boolean;
  defaultValue?: string;
  value?:string;
  disabled?: boolean;
  className?: string;
  registration?: Partial<UseFormRegisterReturn> |any;
};

interface Country {
    code: string;
    icon: FlagComponent;
}

const countries: Country[] = [
    { code: '+250', icon: RW },
    { code: '+254', icon: KE },
    { code: '+255', icon: TZ },
    { code: '+256', icon: UG },
    { code: '+257', icon: BI },
    { code: '+251', icon: ET },
    { code: '+253', icon: DJ },
    // Add more countries as needed
];

export const InputField = (props: InputFieldProps) => {
  const {
    type = "text",
    label,
    placeholder,
    defaultValue,
    focus = false,
    loading = false,
    disabled,
    value,
    onChange, // Add onChange prop
    error,
    registration,
    className,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle input value changes and call onChange prop
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange && onChange(value); // Call onChange if it's provided
  };

  const [open, setOpen] = useState(false);

  const [selected, setSelected] = useState(countries[0]);

  return (
    <FieldWrapper label={label} error={error}>
      <div className="relative flex w-full flex-row gap-1 items-center mb-3">
          {props.type === 'tel' &&
              <div className="mt-2 relative bg-gray-100 p-2 py-[0.60rem] border border-gray-300 rounded-xl ">
                    <div onClick={() => setOpen((prev) => !prev)} className="flex flex-row gap-1">
                        <div className="flex flex-row gap-2"><selected.icon className={"w-4"} /> <p className={"min-w-[20px]"}>{selected.code}</p></div>
                        {open ? <ChevronUpIcon className={'w-4'} /> : <ChevronDownIcon className={"w-4"}/>}
                    </div>
                  {open && <div onMouseLeave={() => setOpen(false)}
                        className="flex flex-col z-[999] absolute left-0 bg-gray-100 mt-4 rounded-2xl border border-gray-400">
                      {countries.map((x, index) => (
                          <div key={index} onClick={() => setSelected(x)}
                               className="flex hover:bg-gray-200 flex-row justify-between gap-1 p-2 w-24">
                              <div className="flex justify-between w-full flex-row gap-2">
                                  <x.icon className={"w-4"}/>
                                  <p className={"min-w-[20px]"}>{x.code}</p>
                              </div>
                          </div>
                      ))}
                  </div>}
              </div>
          }
          <input
              type={type === "password" && showPassword ? "text" : type}
              placeholder={placeholder}
              autoComplete="off"
              autoFocus={focus ?? true}
              value={value}
              disabled={loading || disabled}
          onChange={handleInputChange} // Use the handleInputChange function
          defaultValue={defaultValue}
          className={clsx(
            "w-full h-11 mt-2 rounded-xl border-gray-300 p-4  border-2 !outline-none focus:border-Maincolor  " ,
            error?.message && "border-danger focus:border-danger focus:ring-danger",
            type === "password" && "pr-10",
            className
          )}
          {...registration}
        />
      </div>
    </FieldWrapper>
  );
};
