import config from '@/config';
import {EventModel} from "@/App/Events/types";
import {CheckDataType} from "@/App/Events/single_event";


const TOKEN_NAME = `${config.APP_NAME}-token`;

export const storage = {
	getToken: () => localStorage.getItem(TOKEN_NAME),
	setToken: (token: string) => localStorage.setItem(TOKEN_NAME, token),
	removeToken: () => localStorage.removeItem(TOKEN_NAME),
};

export function storeObjectInLocalStorage(key: string, obj: {event: EventModel | undefined, ticketsData: CheckDataType[]}) {
    try {
        const serializedObj = JSON.stringify(obj);
        localStorage.setItem(key, serializedObj);
        console.log(`Object stored successfully with key: ${key}`);
    } catch (error) {
        console.error('Error storing object in local storage:', error);
    }
}


export function retrieveObjectFromLocalStorage(key: string) {
    try {
        const serializedObj = localStorage.getItem(key);
        if (serializedObj === null) {
            console.log(`No object found with key: ${key}`);
            return null;
        }

        const obj = JSON.parse(serializedObj);
        console.log(`Object retrieved successfully with key: ${key}`);
        return obj as { event: EventModel | undefined, ticketsData: CheckDataType[] };
    } catch (error) {
        console.error('Error retrieving object from local storage:', error);
        return null;
    }
}

export const setDocumentMetaDescription = (description: string) => {
    const metaTag = document.querySelector('meta[name="description"]');

    if (metaTag) {
        // Update the existing meta tag
        metaTag.setAttribute('content', description);
    } else {
        // Create a new meta tag if it doesn't exist
        const newMetaTag = document.createElement('meta');
        newMetaTag.name = 'description';
        newMetaTag.content = description;
        document.head.appendChild(newMetaTag);
    }
};
