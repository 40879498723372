
import React from 'react'
import { Routes, Route } from 'react-router-dom';
import './tailwind.css'
import Login from './Auth/login';
import { Home } from './App/home';
import EventDesc from './App/Events/single_event';
import { Events } from './App/Events/AllEvents';
import Contact from './App/contact';
import { MyTickets } from './App/Tickets/MyTickets';
import { BuyTicket } from './App/Tickets/Buy';
import SignUp from './Auth/signup';
import AfterPayment from './App/Tickets/Buy/AfterPayment';
import PrivacyPolicyPage from "@/App/privacy_policy";
import PastEvents from "@/App/Events/past_events";
import {CategoryEvents} from "@/App/categories";
import TermsConditionsPage from "@/App/terms_n_conditions";
import SingleTicket from "@/App/Tickets/single_ticket";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/event-buy/:id" element={<BuyTicket />}></Route>
      <Route path='/event/:id' element={<   EventDesc />}></Route>
        <Route path='/events-category/:id' element={<CategoryEvents />}></Route>
        <Route path='/ticket/:id' element={<SingleTicket />}></Route>
      <Route path='/login' element={<Login />}></Route>
      <Route path='/signup' element={<SignUp />}></Route>
      <Route path='/events' element={<Events />}></Route>
      <Route path='/contact-us' element={<Contact />}></Route>
      <Route path='/account' element={<MyTickets />}></Route>
      <Route path='/success' element={<AfterPayment />}></Route>
      <Route path='/privacy-policy' element={<PrivacyPolicyPage />}></Route>
      <Route path='/past-events' element={<PastEvents />}></Route>
        <Route path='/terms-and-conditions' element={<TermsConditionsPage />}></Route>
    </Routes>
  );
}

export default App;
