export default function formatDateToLongForm(dateString: any) {
    const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(
        undefined,
        options
    );
    return formattedDate;
}

export const currencyWithAmount = (value: number, symbol: string) =>
    value.toLocaleString(symbol, {
        style: 'currency',
        currency: symbol,
    });
