import baseApi from "@/core/lib/base";
import {MyTicketsResponseModel} from "@/App/Tickets/types";
import {categorySlice} from "@/App/categories/redux";
import {CategoryModel, SingleCategoryModel} from "@/App/categories/types";

export const categoryApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllCategories: builder.query<CategoryModel[],
            {
                pageNumber?: number;
                pageSize?: number;
            }
        >({
            query: (queryParameters) => {
                const {
                    pageNumber,
                    pageSize,
                } = queryParameters;
                return {
                    method: "GET",
                    url: "/api/v1/category",
                    params: {
                        pageNumber,
                        pageSize,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(categorySlice.actions.setCategories(data));
                } catch (error) {
                    throw error;
                }
            },
        }),

        getSingleCategory: builder.query<SingleCategoryModel, {id: string;}
        >({
            query: (queryParameters) => {
                const { id } = queryParameters;
                return {
                    method: "GET",
                    url: `/api/v1/category/${id}`,
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(categorySlice.actions.setSingleCategory(data));
                } catch (error) {
                    throw error;
                }
            },
        }),
    })
});

export const { useGetAllCategoriesQuery, useGetSingleCategoryQuery } = categoryApi;
