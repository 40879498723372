import toast from 'react-hot-toast';
import { storage } from './storage';
import { useEffect } from "react";

export default function useCheckUser() {
    if (storage.getToken() == null) {
        storage.removeToken();
        location.href = '/';
        toast.error('Please Login Again');
    }

    const check = () => {
        return storage.getToken() != null;
    }

    useEffect(() => {
        if(!check()) {
            storage.removeToken();
            location.href = '/login';
            toast.error('Please Login Again');
        }
    }, []);

    return check();
}
