import React, { ReactNode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import App from "./App";
import "./tailwind.css";
import { store } from "./store";

type AppProviderProps = {
  children: ReactNode;
};

function AppProvider(props: AppProviderProps) {
  const { children } = props;
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Provider store={store}>
        <Toaster position="top-center" reverseOrder={false} />
        <BrowserRouter>{children}</BrowserRouter>
      </Provider>
    </Suspense>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);
