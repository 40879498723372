import Footer from '@/components/Footer';
import Header from '@/components/Header';
import SearchForm from '@/components/Search-form';
import { InputField } from '@/components/input';
import { TextareaField } from '@/components/textArea';
import React from 'react';
import { NavLink } from 'react-router-dom';

const Contact = () => {
    return (
        <main className="Container bg-gray-100">
            {/* Header */}

            <Header />
            {/* Contact form */}

            <div className="w-full min-h-[500px]  mt-10">
                <div className="w-10/12 max-w-[1000px] p-5 md:px-10 bg-white  min-h-[500px] mx-auto">
                    <h1 className="w-full font-bold flex gap-2 sm:flex-row text-lg mb-4 uppercase">
                        Send us a <div className="text-Maincolor">Message</div>
                    </h1>
                    <p className="w-full text-[16px] font-Raleway leading-6 mb-10 text-[#222020]">
                        We're thrilled to assist you! Please feel free to get in
                        touch with us for any inquiries, feedback, or
                        assistance. Your satisfaction is our priority.
                    </p>
                    <div className="gap-2 transition-all duration-100 grid grid-cols-1">
                        <div className="gap-2 grid grid-cols-1 sm:grid-cols-2">
                            <InputField
                                type="text"
                                label="Full Name"
                                placeholder="Enter Full Names"
                            />

                            <InputField
                                type="email"
                                label="Email"
                                placeholder="Enter Your Email"
                            />
                        </div>
                        <div className="gap-2 grid grid-cols-1">
                            <InputField
                                type="text"
                                label="Reason"
                                placeholder="Enter Reason"
                            />

                            <div className="w-full">
                                <span className="mb-2 block text-sm text-gray-600  dark:text-gray-400 font-medium ">
                                    Message
                                </span>
                                <textarea
                                    className="w-full outline-none border-2 rounded-xl p-5 border-gray-300"
                                    cols={30}
                                    rows={10}
                                    placeholder='Enter Your Message...'
                                ></textarea>
                            </div>
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="bg-Maincolor  w-full hover:shadow-xl text-white my-4 py-3 px-4 rounded-2xl"
                    >
                        Send Message
                    </button>
                </div>
            </div>

            <Footer />
        </main>
    );
};

export default Contact;
